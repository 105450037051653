import * as React from "react";
import { Fade } from "react-reveal";
import Img1 from "../images/comingsoon-text.png";
import logo from "../images/logo-3.png";
import { Helmet } from "react-helmet";

const IndexPage = () => {
  return (
    // <main style={pageStyles}>
    // <div className="bg-coming-soon-img h-screen bg-no-repeat bg-cover">
    //   <div className=" max-w-6xl m-auto overflow-hidden h-full">
    //     <div className=" h-full flex justify-center items-center flex-col px-4">
    //       {/* <Fade top>
    //         <div className="">
    //           <img src={Img} className="w-28 h-36" alt="icon" />
    //         </div>
    //       </Fade> */}
    //       <Fade top>
    //         <div className="pb-10">
    //           <img src={Img1} alt="img" />
    //         </div>
    //       </Fade>
    //       <Fade bottom>
    //         <div className="text-xl lg:text-6xl font-bold text-white font-Niconne pb-10">
    //           Get Ready for next big thing in AVGSS Hospitality.
    //         </div>
    //       </Fade>
    //     </div>
    //   </div>
    // </div>
    // </main>

    <>
      <Helmet>
        <title>Ametrine24 - AVGSS Hospitality & Fine Dining</title>
        <meta
          property="og:title"
          content="Ametrine24 - AVGSS Hospitality & Fine Dining"
        />
        <meta
          property="twitter:title"
          content="Ametrine24 - AVGSS Hospitality & Fine Dining"
        />
        {/* <meta
            name="description"
            content={
              "Technology and Design Agency creating world class experience centres, AR, VR, MR and the Metaverse"
            }
          />
          <meta
            property="og:description"
            content={
              "Immersionx Technologies - Technology and Design Agency creating world class experience centres, AR, VR, MR and the Metaverse"
            }
          />
          <meta
            property="twitter:description"
            content={
              "Immersionx Technologies - Technology and Design Agency creating world class experience centres, AR, VR, MR and the Metaverse"
            }
          /> */}
      </Helmet>
      <div className="relative">
        <div class="relative flex flex-col justify-center h-screen overflow-hidden ">
          {/* <div class=" p-5 text-2xl text-white bg-purple-300 bg-opacity-50 rounded-xl"> */}
          <div className="max-w-6xl mx-auto flex flex-col lg:flex-row justify-between items-start relative z-30">
            <div className="px-4 ">
              <Fade bottom>
                <div className=" max-w-6xl m-auto overflow-hidden h-full">
                  <div className=" h-full flex justify-center items-center flex-col px-4">
                    <Fade top>
                      <div className="pb-1">
                        <img src={logo} className="w-full h-full" alt="icon" />
                      </div>
                    </Fade>
                    <Fade top>
                      <div className="pb-8">
                        <div className="text-3xl md:text-7xl lg:text-8xl font-bold text-white uppercase text-center">
                          Coming Soon
                        </div>
                        {/* <img src={Img1} alt="img" /> */}
                      </div>
                    </Fade>
                    <Fade bottom>
                      <div className="text-xl md:text-2xl lg:text-4xl font-normal text-white text-center pb-10">
                        Redefining culinary experience
                      </div>
                    </Fade>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
          <div className="html5vid">
            <div className="bg-coming-soon-img h-screen bg-no-repeat bg-cover brightness-50"></div>
            {/* <video
              src={video1}
              autoPlay
              loop
              muted
              playsInline
              poster
              preload="true"
              className="brightness-40"
            ></video> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default IndexPage;

// export const Head = () => <title>Home Page</title>;
